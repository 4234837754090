import BackgroundHeroEnLoopMobile from './background-hero-en-loop-mobile.webp'
import BackgroundHeroEnLoopTablet from './background-hero-en-loop-md.webp'
import BackgroundHeroEnLoopLG from './background-hero-en-loop-lg.webp'
import BackgroundHeroEnLoopXL from './background-hero-en-loop-xl.webp'
import BackgroundHeroEnLoopXXXL from './background-hero-en-loop-xxxl.webp'

import BackgroundEnLoopNextFinancialAppMobile from './background-en-loop-next-financial-super-app-mobile.webp'
import BackgroundEnLoopNextFinancialAppTablet from './background-en-loop-next-financial-super-app-md.webp'
import BackgroundEnLoopNextFinancialAppLG from './background-en-loop-next-financial-super-app-lg.webp'
import BackgroundEnLoopNextFinancialAppXL from './background-en-loop-next-financial-super-app-xl.webp'
import BackgroundEnLoopNextFinancialAppXXXL from './background-en-loop-next-financial-super-app-xxxl.webp'

export const BackgroundHero = {
  mobile: BackgroundHeroEnLoopMobile,
  tablet: BackgroundHeroEnLoopTablet,
  lg: BackgroundHeroEnLoopLG,
  xl: BackgroundHeroEnLoopXL,
  xxl: BackgroundHeroEnLoopXXXL,
}

export const BackgroundNextFinancialApp = {
  mobile: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-loop-us-360/image.webp',
  tablet: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-loop-us-1440-1024-768/image.webp',
  lg: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-loop-us-1440-1024-768/image.webp',
  xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-loop-us-1440-1024-768/image.webp',
  xxl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/dobra2-loop-us-1920/image.webp',
}
