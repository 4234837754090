import styled from 'styled-components'

import { BackgroundNextFinancialApp } from '../../assets/images/_index'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-image: url(${BackgroundNextFinancialApp.mobile});
  height: 780px;
  background-size: cover;

  @media ${device.tablet} {
    background-image: url(${BackgroundNextFinancialApp.tablet});
    height: 512px;
  }

  @media ${device.desktopLG} {
    background-image: url(${BackgroundNextFinancialApp.lg});
    height: 768px;
  }

  @media ${device.desktopXL} {
    background-image: url(${BackgroundNextFinancialApp.xl});
    height: 1024px;
  }

  @media ${device.desktopXXXL} {
    background-image: url(${BackgroundNextFinancialApp.xxl});
    height: 1080px;

    h1 {
      font-size: 72px !important;
      line-height: 90px !important;
    }

    p {
      font-size: 32px !important;
      line-height: 40px !important;      
    }

    li {
      font-size: 24px !important;
      line-height: 28px !important;
    }

    .description {
      font-size: 32px !important;
      line-height: 40px !important;
    }
  }

  .text-block {
    margin-top: 368px;
    @media ${device.tablet} {
      margin-top: 0;
    }
  }

  ul {
    padding-inline-start: 24px;
  }

`

export const LinkButton = styled.a`
  border: 1px solid #FFB46E;
  border-radius: 8px;
  background-color: transparent;
  color: #FF7A00;
  font-size: 10px !important;
  line-height: 12px;
  font-weight: 700;
  padding: 10px 16px;
  width: 192px;
  height: 32px;

  @media ${device.desktopXL} {
    font-size: 14px !important;
    line-height: 18px;
    padding: 16px 24px;
    width: 282px;
    height: 48px;
  }

  &:hover {
    opacity: .7;
    background-color: transparent;
    color: #FF7A00;
  }
`
