import React from 'react'

import pageContext from './pageContext.json'
import * as S from './styles'

import Hero from './sections/hero/_index'
import YourNextFinancialSuperApp from './sections/your-next-financial-super-app/_index'

import Layout from 'src/layouts/BaseLayout'
import FooterUs from 'src/components/Us/FooterUS/_index'

const Us = () => {
  return (
    <S.Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <YourNextFinancialSuperApp />
        <FooterUs section='dobra_03' slugEN={pageContext.slug} bgColor='bg-grayscale--500' lang='en' />
      </Layout>
    </S.Wrapper>
  )
}

export default Us
