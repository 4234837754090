import React from 'react'
import ImageWebp from 'src/components/ImageWebp'

import * as S from './styles'

const Hero = () => {
  return (
    <S.Section role='img' aria-label='a night shot a football field in Florida with full stands and football players on the field.'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-block pr-0'>
            <span className='fs-12 lh-15 fs-lg-17 lh-lg-22 fs-xl-20 lh-xl-26 fw-400 f-sora text-white mb-2'>Coming soon</span>
            <h1 className='fs-36 lh-45 fs-lg-48 lh-lg-60 fs-xl-72 lh-xl-90 f-sora fw-400 text-white mb-3'>Your new <span className='fw-700 title-span d-block'>Rewards program</span></h1>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-20 lh-xl-25 fw-400 f-inter text-white mb-4'>Get rewarded as your financial life thrives with exclusive<span className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-20 lh-xl-25 description d-md-block'> benefits for Orlando City and Orlando Pride fans.</span></p>
            <a href='https://bancointer.go.link/globalAccount/remittance?adj_t=188p5dy7' className='btn btn-orange--extra btn--lg fs-10 fs-xl-14 fw-700 text-none mb-0'>Open your account and get ready</a>
          </div>
          <div className='absolute-logo-container'>
            <ImageWebp
              arrayNumbers={[ 140, 140, 180, 220, 240 ]}
              arrayNumbersHeight={[ 0 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/logo-us/image.webp'
              altDescription='Orlando City and Orlando Pride'
            />
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
