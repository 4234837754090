import React from 'react'
import ChevronRight from 'inter-frontend-svgs/lib/v2/navigation/chevron-right'

import * as S from './styles'

const YourNextFinancialSuperApp = () => {
  return (
    <S.Section className='d-flex align-items-center' role='img' aria-label='A man using an orange phone at the door of a stadium.'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-6 text-block'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 f-sora text-white mb-3'>Your next financial Super App</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-20 lh-xl-25 fw-400 f-inter text-white description mb-4'>A lot of benefits, no maintenance fees.</p>
            <ul className='mb-0'>
              <li className='fs-12 lh-15 fs-lg-14 lh-lg-17 fs-xl-18 lh-xl-22 text-white mb-3'>Send money to +60 countries 24/7</li>
              <li className='fs-12 lh-15 fs-lg-14 lh-lg-17 fs-xl-18 lh-xl-22 text-white mb-3'>Physical and digital debit card with $0 fee</li>
              <li className='fs-12 lh-15 fs-lg-14 lh-lg-17 fs-xl-18 lh-xl-22 text-white mb-3'>Gift Cards with a lot of cashback</li>
              <li className='fs-12 lh-15 fs-lg-14 lh-lg-17 fs-xl-18 lh-xl-22 text-white mb-0'>Investments platform with stocks, ETFs, ADRs and REITs</li>
            </ul>
            <S.LinkButton href='https://bancointer.go.link/globalAccount/remittance?adj_t=188p5dy7' className='btn btn-orange--extra btn--lg rounded-2 fs-10 fs-xl-14 fw-700 text-none mb-0 mt-4'>Open your digital account <ChevronRight width={24} height={24} color='orange--extra' /></S.LinkButton>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default YourNextFinancialSuperApp
