import styled from 'styled-components'

import { BackgroundHero } from '../../assets/images/_index'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  background-image: url(${BackgroundHero.mobile});
  height: 780px;
  background-repeat: no-repeat;
  background-size: cover;

  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.tablet} {
    padding-top: 56px;
    padding-bottom: 56px;
  }

  @media ${device.desktopLG} {
    padding-top: 156px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 178px;
    padding-bottom: 100px;
  }

  position: relative;

  .text-block {
    margin-top: 190px;

    @media ${device.tablet} {
      margin-top: 0;
    }
  }

  @media ${device.tablet} {
    background-image: url(${BackgroundHero.tablet});
    height: 512px;
  }

  @media ${device.desktopLG} {
    background-image: url(${BackgroundHero.lg});
    height: 768px;
  }

  @media ${device.desktopXL} {
    background-image: url(${BackgroundHero.xl});
    height: 1024px;
  }

  @media ${device.desktopXXXL} {
    background-image: url(${BackgroundHero.xxl});
    height: 1080px;

    span {
      font-size: 22px !important;
      line-height: 28px !important;
    }

    h1, .title-span  {
      font-size: 96px !important;
      line-height: 120px !important;
    }

    p, .description {
      font-size: 32px !important;
      line-height: 40px !important;      
    }
  }


  .btn {
    border-radius: 8px;
    font-size: 10px;

    width: 216px;
    height: 32px;

    @media ${device.desktopLG} {
      width: 218px;
    }

    @media ${device.desktopXL} {
      height: 48px;
      font-size: 14px;
      width: 302px;
    }
  }

  .absolute-logo-container {
    display: block;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);

    @media ${device.tablet} {
      bottom: 60px;
    }
  }
`
